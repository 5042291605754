import React from 'react'
import LibrarySong from './LibrarySong'

const Library = ({ songs, setCurrentSong, isPlaying, setIsPlaying, audioRef, setSongs, libraryStatus  }) => {
    return (
        <div className={`library${libraryStatus ? " active-library" : ""}`}>
            <h2>Library</h2>
            <div className="library-songs">
                {songs.map(song => (
                    <LibrarySong song={song} songs={songs} setCurrentSong={setCurrentSong} key={song.id} isPlaying={isPlaying} setIsPlaying={setIsPlaying} audioRef={audioRef} setSongs={setSongs}/>
                ))}
                
            </div>
        </div>
    )
}

export default Library
