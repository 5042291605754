import React from 'react'

import { playAudio } from '../utils'
const LibrarySong = ({ audioRef, setSongs,  song, songs, setCurrentSong, isPlaying, setIsPlaying }) => {
    const songSelectHandler = async () =>{
        setIsPlaying(true);
        await setCurrentSong(song)
        const newSongs = songs.map((state) =>{
            if (state.id === song.id){
                return{
                    ...state,
                    active: true
                }
            }
            else{
                return{
                    ...state,
                    active: false
                }
            }
        });
        setSongs(newSongs);
        playAudio(isPlaying, audioRef)
    }
    return (
        <div onClick={songSelectHandler} className={`library-song${(song.active) ? ' selected' : ""}`}>
            <img alt={song.name} src={song.cover} />
            <div className="song-description">
            <h3>{song.name}</h3>
            <h4>{song.artist}</h4>
            </div>
        </div>
    )
}

export default LibrarySong
