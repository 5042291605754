import { faAngleLeft, faAngleRight, faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'

import { playAudio } from '../utils'
const Player = ({ audioRef, currentSong, setCurrentSong, songs, setSongs, isPlaying, setIsPlaying, songInfo, setSongInfo}) => {
    useEffect(() =>{
        const newSongs = songs.map((state) =>{
            if (state.id === currentSong.id){
                return{
                    ...state,
                    active: true
                }
            }
            else{
                return{
                    ...state,
                    active: false
                }
            }
        });
        setSongs(newSongs);
        playAudio(isPlaying, audioRef)
    },[currentSong]);
    

    const playSongHandler = () =>{
        if (isPlaying){
            audioRef.current.pause();
            setIsPlaying(!isPlaying);
        }
        else{
            audioRef.current.play();
            setIsPlaying(!isPlaying);
        }
    }
    
    const getTime = (time) =>{
        return(
            Math.floor(time / 60) + ":"+ ("0" + Math.floor(time % 60)).slice(-2)
        )
    }
    const dragHandler= (e) =>{
        audioRef.current.currentTime = e.target.value
        setSongInfo({...songInfo, currentTime: e.target.value})
        
    }
    const skipTrackHandler = (direction) =>{
        let currentIndex = songs.findIndex((song) => song.id === currentSong.id)
        if (direction === "skip-forward"){
            setCurrentSong(songs[(currentIndex+1) % songs.length])
            
        }
        if (direction === "skip-backward"){
            if((currentIndex-1) % songs.length === -1){
                setCurrentSong(songs[songs.length - 1])
                return;
            }
            setCurrentSong(songs[(currentIndex-1) % songs.length])
        }

    }
    
    return (
        <div className='player'>
            <div className="time-control">
                <p>{getTime(songInfo.currentTime)}</p>
                <input type="range" onChange={dragHandler} name="" id=""  min={0} max={songInfo.duration || 0} value={songInfo.currentTime}/>
                <p>{getTime(songInfo.duration)}</p>
            </div>
            <div className="play-control">
                <FontAwesomeIcon className='skip-backward' onClick={() => skipTrackHandler('skip-backward')} icon={faAngleLeft} size="2x"/>
                <FontAwesomeIcon className="play" onClick={playSongHandler} icon={(isPlaying) ? faPause : faPlay} size="2x" />
                <FontAwesomeIcon className='skip-forward' onClick={() => skipTrackHandler('skip-forward')} icon={faAngleRight} size="2x" />
            </div>
            
        </div>
    )
}

export default Player
