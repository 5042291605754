import { v4 as uuidv4 } from 'uuid'; 
const chillHop = () =>{
    return([
        {
            name: 'Memories Pt.1',
            cover: 'https://chillhop.com/wp-content/uploads/2021/07/034f52eeaeceb144cca67b0930d705d32fc39e37-1024x1024.jpg',
            artist: 'Ruck P',
            audio: 'https://mp3.chillhop.com/serve.php/?mp3=21659',
            color: ['#FAAD63', '#625277'],
            id: uuidv4(),
            active: true,
        },
        {
            name: 'Memories Pt.2',
            cover: 'https://chillhop.com/wp-content/uploads/2021/07/034f52eeaeceb144cca67b0930d705d32fc39e37-1024x1024.jpg',
            artist: 'Ruck P',
            audio: 'https://mp3.chillhop.com/serve.php/?mp3=21660',
            color: ['#FAAD63', '#625277'],
            id: uuidv4(),
            active: false,
        },
        {
            name: 'Caravan',
            cover: 'https://chillhop.com/wp-content/uploads/2021/06/23b2ff959731b56ea8545828b462311e8b1a2bcc-1024x1024.jpg',
            artist: 'goosetaf, The Field Tapes, Makzo',
            audio: 'https://mp3.chillhop.com/serve.php/?mp3=20122',
            color: ['#63794E', '#F3E9CE'],
            id: uuidv4(),
            active: false,
        },
        {
            name: 'LuvnYou',
            cover: 'https://chillhop.com/wp-content/uploads/2021/07/875b9624506ae8d05750b9e4ab4579abb1fe3e16-1024x1024.jpg',
            artist: 'Chillhop Music',
            audio: 'https://mp3.chillhop.com/serve.php/?mp3=21784',
            color: ['#49878E', '#2E1C1B'],
            id: uuidv4(),
            active: false,
        },
        {
            name: 'Fallin\'',
            cover: 'https://chillhop.com/wp-content/uploads/2021/07/034f52eeaeceb144cca67b0930d705d32fc39e37-1024x1024.jpg',
            artist: 'Ruck P, Jaz Lund',
            audio: 'https://mp3.chillhop.com/serve.php/?mp3=20561',
            color: ['#FAAD63', '#625277'],
            id: uuidv4(),
            active: false,
        },
        {
            name: 'Industry Baby',
            cover: 'https://www.nme.com/wp-content/uploads/2021/07/lil-nas-x-industry-baby@2000x1270-696x442.jpg',
            artist: 'Lil Nas X',
            audio: 'https://www.Amebo9ja.com/wp-content/music/2021/07/Lil_Nas_X_Ft_Jack_Harlow_-_Industry_Baby_Amebo9ja.com.mp3?_=1',
            color: ['#A3D9FD', '#482A2A'],
            id: uuidv4(),
            active: false,
        },
    ])
}
export default chillHop